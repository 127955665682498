import { Method } from "~/enums";
import moment from 'moment';


export const useUserStore = defineStore("userStore", {
  state: () => ({
   userInfo: useSessionStorage('userInfo', {} as any),
   userSeq: useSessionStorage('userSeq', 0 as number),
   userLoginInfo: useSessionStorage('userLoginInfo', {} as any),
   accessToken: useSessionStorage('accessToken', '' as string),
   refreshToken: useSessionStorage('refreshToken', '' as string),
  }),
  getters: {},
  actions: {
    async login(userId: string, password: string){
      
      try{
        const res = await useFetch('/api/v1/signin', {method: Method.POST, query:{userId: userId, password: password}}) as any;

        if(res.data.value === null && res.error.value !== null){
          throw res.error.value;
        }

        this.userInfo = res.data.value;

        let options = {
          expires: moment().add(res.data.value.refreshTokenExpiresIn, 'second').toDate(),
        }

        let cookies = useCookie('userToken', options);
        cookies.value = res.data.value.refreshToken;

        this.userSeq = res.data.value.userSeq;
        this.accessToken = res.data.value.accessToken;
        this.refreshToken = res.data.value.refreshToken;

        return res.data.value;
      } catch(e){

        throw e;
      }
 
    },
    async getUserInfo(userSeq: number){
      try{
        const res = await useCustomFetch(`/api/v1/users/${userSeq}`, {method: Method.GET});

        this.userLoginInfo = res.data.value;
      } catch(e){
        throw e;
      }
    },
    async logout(){
      try{
        this.userInfo = {};
        this.userSeq = 0;
        this.userLoginInfo = {};
        this.accessToken = '';
        this.refreshToken = '';
      }catch(e){
        throw e
      }
    }
  }
})
